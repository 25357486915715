/* eslint-disable no-restricted-imports */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { ConfirmDelete } from '@/components/Modal';
import { Table } from '@/components/Table';
import { useGetAllLoans } from '@/features/loans/hooks';
import { useGetAllWallets } from '@/features/wallet/hooks';
import { notifyError, notifySuccess } from '@/utils/notifications';

import { ConfirmSuspend } from '../components/ConfirmSuspend';
import { TableMenu } from '../components/TableMenu';
import { useDeleteUser, useGetUsers, useSuspendUser } from '../hooks';

export const Users = () => {
  const [populatedUsers, setPopulatedUsers] = useState<any>(null);
  const { data: users, isLoading: isUsersLoading } = useGetUsers();
  const { data: loans, isLoading: isLoansLoading } = useGetAllLoans();
  const { data: wallets, isLoading: isWalletsLoading } = useGetAllWallets();
  const [isDeleteConfirmationOpen, toggleIsDeleteConfirmationOpen] =
    useState(false);
  const [isSuspendConfirmationOpen, toggleIsSuspendConfirmationOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const {
    mutate: deleteUser,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    error: deleteError,
    reset: deleteReset,
  } = useDeleteUser();

  const {
    mutate: suspendUser,
    isLoading: isSuspendLoading,
    isSuccess: isSuspendSuccess,
    isError: isSuspendError,
    error: suspendError,
    reset: suspendReset,
  } = useSuspendUser();

  useEffect(() => {
    if (users && loans && wallets) {
      const populatedData = users.map((user: any) => ({
        ...user,
        wallet: wallets.find((wallet: any) => wallet.user?._id === user._id),
        loans: loans.resource.find((loan: any) => loan.user?._id === user._id),
      }));

      setPopulatedUsers(populatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUsersLoading,
    isLoansLoading,
    isWalletsLoading,
    isDeleteSuccess,
    users,
  ]);

  const handleSuspend = useCallback((_id: string) => {
    setSelectedUser(_id);
    toggleIsSuspendConfirmationOpen(true);
  }, []);

  const handleDelete = useCallback((_id: string) => {
    setSelectedUser(_id);
    toggleIsDeleteConfirmationOpen(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'menu',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className=''>
            <TableMenu
              _id={row.original._id}
              handleSuspend={() => handleSuspend(row.original._id)}
              handleDelete={() => handleDelete(row.original._id)}
            />
          </div>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Is Suspended?',
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row.original.isSuspended ? 'text-red-500' : 'text-green-500'
              }
            >
              {row.original.isSuspended ? 'Yes' : 'No'}
            </span>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Status',
        Cell: ({ row }: any) => {
          return (
            <span
              className={
                row.original.isActivated ? 'text-green-500' : 'text-red-500'
              }
            >
              {row.original.isActivated ? 'Active' : 'Inactive'}
            </span>
          );
        },
      },

      {
        Header: 'Referrals',
        accessor: 'referralCount',
      },
      {
        Header: 'Netpoints',
        accessor: 'wallet.netPoints',
      },
      {
        Header: 'Rank',
        accessor: 'wallet.networkerRank',
      },
      {
        Header: 'Reserved Capital($)',
        Cell: ({ row }: any) => {
          return (
            <span>
              {row.original.wallet?.promotionalBalance.toLocaleString()}
            </span>
          );
        },
      },
      {
        Header: 'Profit Balance($)',
        Cell: ({ row }: any) => {
          return <span>{row.original.wallet?.amount.toLocaleString()}</span>;
        },
      },
      // {
      //   Header: 'Portfolio Value',
      //   accessor: ''
      // },
      // {
      //   Header: 'Unmature ROI',
      //   accessor: ''
      // },
      // {
      //   Header: 'Shares',
      //   accessor: ''
      // },
    ],
    [],
  );

  if (isDeleteSuccess) {
    notifySuccess('User has been deleted successfully', false);
    deleteReset();
  }

  if (isDeleteError) {
    notifyError((deleteError as { message: string }).message as string, false);
    deleteReset();
  }

  if (isSuspendSuccess) {
    notifySuccess('User has been suspended successfully', false);
    suspendReset();
  }

  if (isSuspendError) {
    console.log(suspendError);

    notifyError((suspendError as { message: string }).message as string, false);
    suspendReset();
  }

  return (
    <div>
      <Head title='Users' />
      {isUsersLoading || isLoansLoading || isWalletsLoading ? (
        <div className='flex h-screen justify-center items-center'>
          <Spinner size='md' />
        </div>
      ) : (
        <div className='overflow-hidden '>
          <Table
            data={populatedUsers ?? []}
            columns={columns}
            showPagination={true}
          />
        </div>
      )}
      <ConfirmDelete
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={toggleIsDeleteConfirmationOpen}
        deleteDocument={deleteUser}
        isLoading={isDeleteLoading}
        selectedData={selectedUser}
      />

      <ConfirmSuspend
        isOpen={isSuspendConfirmationOpen}
        setIsOpen={toggleIsSuspendConfirmationOpen}
        suspendDocument={suspendUser}
        isLoading={isSuspendLoading}
        selectedData={selectedUser}
      />
      <ToastContainer />
    </div>
  );
};
